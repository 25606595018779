import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Button, notification } from "antd";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { CheckCircleOutlined, DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";


export default function Infrastrucure(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const [superficieTerrain,setSuperficieTerrain] = useState('');
    const [superficieBatie, setSuperficieBatie] = useState('');
    const [superficieReste, setSuperficieReste] = useState('');
    const [terraintitrer, setTerrainTitrer] = useState(false);
    const [proprietaireTerrain, setProprietaireTerrain] = useState('oui');
    const [coursSoir, setCoursSoir] = useState(false);
    const [nomCoursSoir, setNomCoursSoir] = useState('');
    const [nbSalleFonctionelle, setNbSalleFontionelle] = useState(0);
    const [nbSalleNonFonctionelle, setNbSalleNomFonctionelle] = useState(0);
    const [bibliotheque, setBiblioteque] = useState(false);
    const [nbOuvrage, setNbOuvrage] = useState(0);
    const [nbAireJeux, setNbAireJeux] = useState(0);
    const [superficie, setSuperficie] = useState('');
    const [cantineFonctionelle, setCantineFonctionelle] = useState(false);
    const [nbPointEau, setNbPointEaux] = useState(0);
    const [capacite, setCapacite] = useState('');
    const [nbEleveAbonnee, setNbEleveAbonnee] = useState('0');
    const [infirmerie, setInfirmerie] = useState(false);
    const [boitePharmatie, setBoitePharmatie] = useState(false);
    const [nbTableBanc, setNbTableBanc] = useState('0,0,0,0,0');
    const [nbTables, setNbTables] = useState('0,0');
    const [nbChaise, setNbChaise] = useState(0);
    const [branchementEauCourant, setBranchementEauCourant] = useState(false);
    const [electrifie, setElectrifie] = useState(false);
    const [nbToilettesModerne, setNbToilettesModerne] = useState(0);
    const [nbToilettesIndigene, setNbToilettesIndigene] = useState(0);
    const [cooperativeScolaire, setCoorperativeScolaire] = useState(false);
    const [titre, setTitre] = useState(false);

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
     },[]);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };

    const handleSend = () => {
        setLoading(true);
        axios.post(APP_URL+'esb/save-infrastructure',{
            'superficieTerrain':superficieTerrain,
            'superficieBatie':superficieBatie,
            'superficieReste':superficieReste,
            'terraintitrer':terraintitrer,
            'proprietaireTerrain':proprietaireTerrain,
            'coursSoir':coursSoir,
            'nomCoursSoir':nomCoursSoir,
            'nbSalleFonctionelle':nbSalleFonctionelle,
            'nbSalleNonFonctionelle':nbSalleNonFonctionelle,
            'bibliotheque':bibliotheque,
            'nbOuvrage':nbOuvrage,
            'nbAireJeux':nbAireJeux,
            'superficie':superficie,
            'cantineFonctionelle':cantineFonctionelle,
            'nbPointEau':nbPointEau,
            'capacite':capacite,
            'nbEleveAbonnee':nbEleveAbonnee,
            'infirmerie':infirmerie,
            'boitePharmatie':boitePharmatie,
            'nbTableBanc':nbTableBanc,
            'nbTables':nbTables,
            'nbChaise':nbChaise,
            'branchementEauCourant':branchementEauCourant,
            'electrifie':electrifie,
            'nbToilettesModerne':nbToilettesModerne,
            'nbToilettesIndigene':nbToilettesIndigene,
            'cooperativeScolaire':cooperativeScolaire,
            'rentreer_esb' : datas.rentreer_esb,
            'titre':titre,
        },config).then((response) => {
            setLoading(false);
            setRelationId(response.data.infrastructure_id);

            let localData  = readData(); // on recupere les donnees de la memoire local
            localData.relationId = response.data.infrastructure_id; // on enregistre la vue en cours
            localData.keyView = 'type_sale';
            saveData(JSON.parse(JSON.stringify(localData))); // on enregistre 

            // on notifie 
            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

              setMainView('type_sale');

        }).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }



    return (
        <>
        <div class="container-fluid">

        <button style={{width:'20%'}} onClick={() => { 

            setRelationId(datas.rentreer_esb); //on passe l'ID du rapport
            setMainView('trans1'); // on passe la cle de la vue a retourner

         }}  className="btn btn-success" >
            <DoubleLeftOutlined />
            &nbsp;&nbsp;
             retour
             
         </button>

         <br /><br />
          <div class="card mb-3">
            <div class="card-body">
                <h2> Infrastruture </h2>
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-body form-container">
                <h6>Superficie du terrain de l'établissement (m<sup>2</sup>)</h6>
                <input type="number" value={superficieTerrain} onChange={(e) => setSuperficieTerrain(e.target.value)} className="form-control" />
                
                <br />
                <h6>Superficie Batie (m<sup>2</sup>)</h6>
                <input type="number" value={superficieBatie} onChange={(e) => setSuperficieBatie(e.target.value)} className="form-control" />

                <br />
                <h6>Superficie restante</h6>
                <input type="number" value={superficieReste} onChange={(e) => setSuperficieReste(e.target.value)} className="form-control" />
                
                <br /><hr />
                <h6>Le terrain est-il titré ?</h6>
                <div className="radio-group">
                    <input type="radio" name="titre" onClick={() => setTerrainTitrer(true)} id="oui" />
                    <label htmlFor="oui">OUI</label>
                    <input type="radio" name="titre" onClick={() => setTerrainTitrer(false)} id="non" />
                    <label htmlFor="non">NON</label>
                </div>

                <br /><hr />
                <h6>L'établissement est-il propriétaire du terrain ?</h6>
                <div className="radio-group">
                    <input type="radio" name="a" id="a" onClick={() => setProprietaireTerrain(true)} />
                    <label htmlFor="a">OUI</label>
                    <input type="radio" name="a" id="b" onClick={() => setProprietaireTerrain(false)} />
                    <label htmlFor="b">NON</label>
                </div>

                <br /><hr />
                <h6>L'établissement abrite-t-il un cours du soir ?</h6>
                <div className="radio-group">
                    <input type="radio" name="b" id="cs" onClick={() => setCoursSoir(true)} />
                    <label htmlFor="cs">OUI</label>
                    <input type="radio" name="b" id="cs2" onClick={() => setCoursSoir(false)} />
                    <label htmlFor="cs2">NON</label>
                </div>

                <br />
                <h6>Quel est le nom de ce cours du soir ?</h6>
                <input type="text" value={nomCoursSoir} onChange={(e) => setNomCoursSoir(e.target.value)} className="form-control" />

                <br />
                <h6>Nombre de salles de classe fonctionnelles</h6>
                <input type="number" value={nbSalleFonctionelle} onChange={(e) => setNbSalleFontionelle(e.target.value)} className="form-control" />

                <br />
                <h6>Nombre de salles de classe non fonctionnelles</h6>
                <input type="number" value={nbSalleNonFonctionelle} onChange={(e) => setNbSalleNomFonctionelle(e.target.value)} className="form-control" />

                <br />
                <h6>Avez-vous une bibliothèque ?</h6>
                <div className="radio-group">
                    <input type="radio" name="c" id="c" onClick={() => setBiblioteque(true)} />
                    <label htmlFor="c">OUI</label>
                    <input type="radio" name="c" id="d" onClick={() => setBiblioteque(false)} />
                    <label htmlFor="d">NON</label>
                </div>

                <br />
                <h6>Si oui, nombre d'ouvrages</h6>
                <input type="number" value={nbOuvrage} onChange={(e) => setNbOuvrage(e.target.value)} className="form-control" />

                <br />
                <h6>Combien avez-vous d'aires de jeux ?</h6>
                <input type="text" value={nbAireJeux} onChange={(e) => setNbAireJeux(e.target.value)} className="form-control" />

                <br />
                <h6>Superficie (m<sup>2</sup>)</h6>
                <input type="text" value={superficie} onChange={(e) => setSuperficie(e.target.value)} className="form-control" />

                <br />
                <h6>Avez-vous une cantine scolaire fonctionnelle ?</h6>
                <div className="radio-group">
                    <input type="radio" name="d" id="qf" onClick={() => setCantineFonctionelle(true)} />
                    <label htmlFor="qf">OUI</label>
                    <input type="radio" name="d" id="qf2" onClick={() => setCantineFonctionelle(false)} />
                    <label htmlFor="qf2">NON</label>
                </div>

                <br />
                <h6>Nombre de points d'eau</h6>
                <input type="number" value={nbPointEau} onChange={(e) => setNbPointEaux(e.target.value)} className="form-control" />

                <br />
                <h6>Capacité</h6>
                <input type="number" value={capacite} onChange={(e) => setCapacite(e.target.value)} className="form-control" />

                <br />
                <h6>Nombre d'élèves abonnés</h6>
                <input type="number" value={nbEleveAbonnee} onChange={(e) => setNbEleveAbonnee(e.target.value)} className="form-control" />

                <br />
                <h6>Avez-vous une infirmerie ?</h6>
                <div className="radio-group">
                    <input type="radio" name="e" id="if" onClick={() => setInfirmerie(true)} />
                    <label htmlFor="if">OUI</label>
                    <input type="radio" name="e" id="if2" onClick={() => setInfirmerie(false)} />
                    <label htmlFor="if2">NON</label>
                </div>

                <hr />
                <h6>Avez-vous une boîte à pharmacie ?</h6>
                <div className="radio-group">
                    <input type="radio" name="f" id="bp" onClick={() => setBoitePharmatie(true)} />
                    <label htmlFor="bp">OUI</label>
                    <input type="radio" name="f" id="bf2" onClick={() => setBoitePharmatie(false)} />
                    <label htmlFor="bf2">NON</label>
                </div>

                <br />
                <h6>Nombre de tables bancs à 01 place, 02 places, 03 places, 04 places, 05 places (séparé par des virgules)</h6>
                <input type="text" value={nbTableBanc} onChange={(e) => setNbTableBanc(e.target.value)} className="form-control" />

                <br />
                <h6>Nombre de tables bancs à 01 place, 02 places (séparé par des virgules)</h6>
                <input type="text" value={nbTables} onChange={(e) => setNbTables(e.target.value)} className="form-control" />

                <br />
                <h6>Nombre de chaises</h6>
                <input type="text" value={nbChaise} onChange={(e) => setNbChaise(e.target.value)} className="form-control" />

                <br />
                <h6>Votre établissement a-t-il un branchement en eau courante ?</h6>
                <div className="radio-group">
                    <input type="radio" name="g" id="ac" onClick={() => setBranchementEauCourant(true)} />
                    <label htmlFor="ac">OUI</label>
                    <input type="radio" name="g" id="ac2" onClick={() => setBranchementEauCourant(false)} />
                    <label htmlFor="ac2">NON</label>
                </div>

                <hr />
                <h6>Votre établissement est-il électrifié ?</h6>
                <div className="radio-group">
                    <input type="radio" name="h" id="ee" onClick={() => setElectrifie(true)} />
                    <label htmlFor="ee">OUI</label>
                    <input type="radio" name="h" id="ee2" onClick={() => setElectrifie(false)} />
                    <label htmlFor="ee2">NON</label>
                </div>

                <br />
                <h6>Combien de toilettes modernes existe-t-il ?</h6>
                <input type="number" value={nbToilettesModerne} onChange={(e) => setNbToilettesModerne(e.target.value)} className="form-control" />

                <br />
                <h6>Combien de toilettes indigènes existe-t-il ?</h6>
                <input type="number" value={nbToilettesIndigene} onChange={(e) => setNbToilettesIndigene(e.target.value)} className="form-control" />
                <br />
                <h6>Existe-t-il une coopérative scolaire dans votre établissement ?</h6>
                <br />
                <div className="radio-group">
                    <input type="radio" name="i" id="cc" onClick={() => setCoorperativeScolaire(true)} />
                    <label htmlFor="cc">OUI</label>
                    <input type="radio" name="i" id="cc2" onClick={() => setCoorperativeScolaire(false)} />
                    <label htmlFor="cc2">NON</label>
                </div>
                <br />

                <button disabled={loading} onClick={handleSend} className="btn btn-primary">
                  {loading?<>Enregistrement en cours</>:<>Suivant</>}
                   &nbsp; <DoubleRightOutlined /> </button>
            </div>
        </div>
        <br /><br /><br />
        </div>

        <style jsx>{`
          .radio-group {
    display: flex;
    align-items: center;
    gap: 10px;
}

 .form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.radio-group label {
    margin-right: 15px;
}

.form-control {
    margin-bottom: 15px;
}

.btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.btn-primary {
    background-color: #007bff;
    border: none;
    color: white;
}

.btn-primary:disabled {
    background-color: #c0c0c0;
    cursor: not-allowed;
}

        `}</style>
        </>
    );
}