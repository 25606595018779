import { Button, notification } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { APP_URL } from "../../../../URL";
import { useCookies } from "react-cookie";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useRecoilState } from "recoil";
import { CheckCircleOutlined, DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";

export default function Trans2(){

  const [mainView, setMainView] = useRecoilState(mainViewState);

    const [nbSallesClasse, setNbSallesClass] = useState('');
    const [dureSallesClasse, setDureSallesClasse] = useState('');
    const [semidurSallesClasse, setSemidureSallesClasse] = useState('');
    const [matProvSallesClasse, setMatProvSallesClasse] = useState('');

    const [nbSallesEnseignants, setNbSallesEnseignants] = useState('');
    const [dureSallesEnseignant, setDureSallesEnseignant] = useState('');
    const [semidurSallesEnseignant, setSemidurSallesEnseignant] = useState('');
    const [matProvSallesEnseignant, setMatProvSallesEnseignant] = useState('');

    const [nbBibliotheque, setNbBibliotheque] = useState('');
    const [dureBibliotheque, setDureBibliotheque] = useState('');
    const [semiDurBibliotheque, setSemidurBibliotheque] = useState('');
    const [matProvBibliotheque, setMatProvBibliotheque] = useState('');

    const [nbSalleInformatique, setNbSalleInformatique] = useState('');
    const [dureSalleInformatique, setDureSalleInformatique] = useState('');
    const [semidurSalleInformatique, setSemidurSalleInformatique] = useState('');
    const [matProvSalleInformatique, setMatProvSalleInformatique] = useState('');

    const [nbInfirmerie, setNbInfirmerie] = useState('');
    const [dureInfirmerie, setDureInfirmerie] = useState('');
    const [semidurInfirmerie, setSemiDurInfirmerie] = useState('');
    const [matProvInfirmerie, setMatProvInfirmerie] = useState('');

    const [nbToilettesFille, setNbToilettesFille] = useState('');
    const [dureToilettesFille, setDureToilettesFille] = useState('');
    const [semidurToilettesFille, setSemiDurToilettesFille] = useState('');
    const [matProvToilettesFille, setMatProvToilettesFille] = useState('');

    const [nbToilettesGarcon, setNbToilettesGarcon] = useState('');
    const [dureToilettesGarcon, setDureToilettesGarcon] = useState('');
    const [semidurToilettesGarcon, setSemiDurToilettesGarcon] = useState('');
    const [matProvToilettesGarcon, setMatProvToilettesGarcon] = useState('');

    const [nbToilettesBarriere, setNbToilettesBarriere] = useState('');
    const [dureToilettesBarriere, setDureToilettesBarriere] = useState('');
    const [semidurToilettesBarriere, setSemiDurToilettesBarriere] = useState('');
    const [matProvToilettesBarriere, setMatProvToilettesBarriere ] = useState('');
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const initialData = [
      { order: 1, type: 'Salles de classe / Classrooms', number: 0, definite: 0, semi_definite: 0, temporary: 0 },
      { order: 2, type: 'Salle des enseignants / Staff room', number: 0, definite: 0, semi_definite: 0, temporary: 0 },
      { order: 3, type: 'Bibliothèque / Library', number: 0, definite: 0, semi_definite: 0, temporary: 0 },
      { order: 4, type: 'Salle d\'informatique / Computer lab', number: 0, definite: 0, semi_definite: 0, temporary: 0 },
      { order: 5, type: 'Infirmerie / Sick bay', number: 0, definite: 0, semi_definite: 0, temporary: 0 },
      { order: 6, type: 'Toilettes filles / Females toilets', number: 0, definite: 0, semi_definite: 0, temporary: 0 },
      { order: 7, type: 'Toilettes garçons / Males toilets', number: 0, definite: 0, semi_definite: 0, temporary: 0 },
      { order: 8, type: 'Barrière / Gate', number: 0, definite: 0, semi_definite: 0, temporary: 0 },
  ];

  const [data, setData] = useState(initialData);

  const handleChange = (index, field, value) => {
      const newData = [...data];
      newData[index][field] = value;
      setData(newData);
  };

    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.seduc_token}`
      };
    
    const config = {
      headers: headers,
      };

      const {saveData,readData} = useLocalStorage();
      const datas = readData();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },[]);


    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
          await axios.post(APP_URL+'save-new-infrastructure/'+datas.rentreer_esb+'/'+datas.relationId, data,config);
          
          setLoading(false);
          notification.open({
            message:(
               <div style={{ color: 'white' }}>
                  Enregistrer avec success
               </div>
              ),
            description: '',
            placement:'bottomLeft', 
            icon: <CheckCircleOutlined />,
            style: {
                backgroundColor: 'green', // Couleur de fond personnalisée
                color:'white',
                // Autres styles personnalisés...
              },
          })
  
          let localData  = readData(); // on recupere les donnees de la memoire local
          localData.keyView = 'administration'; // on enregistre la vue en cours
          // localData.relationId = datas.relationId;
  
          saveData(JSON.parse(JSON.stringify(localData)));
  
          setMainView('administration');

      } catch (error) {
          console.error(error);
          setLoading(false);
      }
  };

   /* const handleSumit = () => {
      setLoading(true);
      axios.post(APP_URL + 'esb/save-trans2',{
        'nbSallesClasse':nbSallesClasse,
        'dureSallesClasse':dureSallesClasse,
        'semidurSallesClasse':semidurSallesClasse,
        'matProvSallesClasse':matProvSallesClasse,
        'nbSallesEnseignants':nbSallesEnseignants,
        'dureSallesEnseignant':dureSallesEnseignant,
        'semidurSallesEnseignant':semidurSallesEnseignant,
        'matProvSallesEnseignant':matProvSallesEnseignant,
        'nbBibliotheque':nbBibliotheque,
        'dureBibliotheque':dureBibliotheque,
        'semiDurBibliotheque':semiDurBibliotheque,
        'matProvBibliotheque':matProvBibliotheque,
        'nbSalleInformatique':nbSalleInformatique,
        'dureSalleInformatique':dureSalleInformatique,
        'semidurSalleInformatique':semidurSalleInformatique,
        'matProvSalleInformatique':matProvSalleInformatique,
        'nbInfirmerie':nbInfirmerie,
        'dureInfirmerie':dureInfirmerie,
        'semidurInfirmerie':semidurInfirmerie,
        'matProvInfirmerie':matProvInfirmerie,
        'nbToilettesFille':nbToilettesFille,
        'dureToilettesFille':dureToilettesFille,
        'semidurToilettesFille':semidurToilettesFille,
        'matProvToilettesFille':matProvToilettesFille,
        'nbToilettesGarcon':nbToilettesGarcon,
        'dureToilettesGarcon':dureToilettesGarcon,
        'semidurToilettesGarcon':semidurToilettesGarcon,
        'matProvToilettesGarcon':matProvToilettesGarcon,
        'matProvToilettesGarcon':matProvToilettesGarcon,
        'nbToilettesBarriere':nbToilettesBarriere,
        'dureToilettesBarriere':dureToilettesBarriere,
        'semidurToilettesBarriere':semidurToilettesBarriere,
        'matProvToilettesBarriere':matProvToilettesBarriere,
        'rentreer_esb':datas.rentreer_esb,
        'infrastructure_id':datas.relationId
      },config).then((response) => {

        notification.open({
          message:(
             <div style={{ color: 'white' }}>
                Enregistrer avec success
             </div>
            ),
          description: '',
          placement:'bottomLeft', 
          icon: <CheckCircleOutlined />,
          style: {
              backgroundColor: 'green', // Couleur de fond personnalisée
              color:'white',
              // Autres styles personnalisés...
            },
        })

        let localData  = readData(); // on recupere les donnees de la memoire local
        localData.keyView = 'administration'; // on enregistre la vue en cours
        localData.relationId = null;

        saveData(JSON.parse(JSON.stringify(localData)));

        setMainView('administration');

      }).catch((error) => {
        console.log(error);
        setLoading(false);
      })
    } */
    


    return (
        <>
         <div class="container-fluid">

<button style={{width:'20%'}} onClick={() => { 

setRelationId(datas.rentreer_esb); //on passe l'ID du rapport
setMainView('infrastructure'); // on passe la cle de la vue a retourner

}}  className="btn btn-success" >
<DoubleLeftOutlined />
&nbsp;&nbsp;
 retour
 
</button>

<br /><br />

          <div class="card mb-3">
          <div class="card-body">
                <h2> Types de salles </h2>
          </div>
          </div>

          <div class="card mb-3">
          <div class="card-body">

          <form onSubmit={handleSubmit}>
            <table className="table" border={1}>
                <thead>
                    <tr>
                        <th rowSpan="2">Ordre<br/>Order</th>
                        <th rowSpan="2">Types de salles<br/> <i>Types of class</i></th>
                        <th rowSpan="2">Nombre<br/> <i>Number</i></th>
                     
                        <th>Dur<br/><i>Dure</i></th>
                        <th>Sémi-dur<br/><i>Semi definite</i></th>
                        <th>Mat Prov<br/><i>Temporary material</i></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr>
                            <td>{row.order}</td>
                            <td>{row.type}</td>
                            <td>
                                <input
                                    type="number"
                                    value={row.number}
                                    onChange={(e) => handleChange(index, 'number', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={row.definite}
                                    onChange={(e) => handleChange(index, 'definite', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={row.semi_definite}
                                    onChange={(e) => handleChange(index, 'semi_definite', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={row.temporary}
                                    onChange={(e) => handleChange(index, 'temporary', e.target.value)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button type="submit" disabled={loading}>
                {loading?<>Enregistrement en cours</>:<>Suivant</>}
                &nbsp;
               <DoubleRightOutlined />
            </button>
        </form>

          </div>
          </div>

          </div>

          <br /><br /><br />
        </>
    );
}