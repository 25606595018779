import { Button, Result, Spin } from "antd";
import { useRecoilState } from "recoil";
import { mainViewState } from "../../../../atoms/AppAtome";
import { useEffect, useState } from "react";
import { APP_URL } from "../../../../URL";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import axios from "axios";
import { useCookies } from "react-cookie";


export default function Endd(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [loading, setLoading] = useState(true);
    const {saveData,readData} = useLocalStorage();
    const datas = readData();

    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.seduc_token}`
      };

      const config = {
        headers: headers, 
        };

    useEffect(() => {

      axios.get(APP_URL+'get-pdf-rentree-esb/'+datas.rentreer_esb, {
        responseType: 'blob',
        headers: headers,

    })
    .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report_rentree_education_base.pdf');
        document.body.appendChild(link);
        link.click();

        setLoading(false);

    })
    .catch((error) => {
        console.error('Error generating PDF', error);
        setLoading(false);
    });

    },[])

    return (
        <>

  {loading?<>
  
  <center>
    <br /><br /> <br /><br />
    <Spin size="large" />
    <br /><br />
    <span style={{fontFamily:'verdana'}}>Generation du Fichier PDF en cours..</span>
  </center>
  
  </>:<>
  
  <Result
    status="success"
    title="Bravo.. vous avez terminer avec vos enregistrements"
    subTitle="Vous pouvez routourner en acceuil mainant"
    extra={[
      <>
      <Button type="primary" key="console" onClick={() => setMainView('dashboad')}>
        Go HOME
      </Button>
      </>
      ,
    //   <Button key="buy">Buy Again</Button>,
    ]}
  />
  
  </>}
 
        </>
    );
}