import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { aspectsFinanciersIdState, mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useCookies } from "react-cookie";
import { Button, notification } from "antd";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { CheckCircleOutlined, DoubleLeftOutlined } from "@ant-design/icons";


export default function Recette(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
    const [aspectFinancierId, setAspectFinancierid] = useRecoilState(aspectsFinanciersIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();

    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };

       
    const [fraisInscription, setFraiInscription] = useState('');
    const [ecolage, setEcolage] = useState('');
    const [subvention, setSubvention] = useState('');
    const [autreResource, setAutreResource] = useState('');

    const [investissement, setInvestissement] = useState('');
    const [fonctionnement, setFonctionnement] = useState('');

    const [recettes, setRecettes] = useState([
        { description: 'Frais d\'inscription', montant: 0 },
        { description: 'Écolage', montant: 0 },
        { description: 'Subventions', montant: 0 },
        { description: 'Autres ressources', montant: 0 },
      ]);
    
      const [depenses, setDepenses] = useState([
        { description: 'Investissement', montant: 0 },
        { description: 'Fonctionnement', montant: 0 },
      ]);

      const [totalRecettes, setTotalRecettes] = useState(0);
      const [totalDepenses, setTotalDepenses] = useState(0);
      const [balance, setBalance] = useState(0);

      useEffect(() => {
        const newTotalRecettes = recettes.reduce((total, item) => total + item.montant, 0);
        setTotalRecettes(newTotalRecettes);
    
        const newTotalDepenses = depenses.reduce((total, item) => total + item.montant, 0);
        setTotalDepenses(newTotalDepenses);
    
        setBalance(newTotalRecettes - newTotalDepenses);
      },[recettes, depenses]);

      const handleRecetteChange = (index, value) => {
        const updatedRecettes = [...recettes];
        updatedRecettes[index].montant = parseFloat(value) || 0;
        setRecettes(updatedRecettes);
      };
    
      const handleDepenseChange = (index, value) => {
        const updatedDepenses = [...depenses];
        updatedDepenses[index].montant = parseFloat(value) || 0;
        setDepenses(updatedDepenses);
      };

      const handleSubmit = async () => {

        setLoading(true);

        try {

          await axios.post(APP_URL+'save-budget-previsionnel2/'+datas.rentreer_esb, { recettes, depenses, totalRecettes, totalDepenses, balance },config).then((response) => {
            
            let localData  = readData(); // on recupere les donnees de la memoire local
            localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
            localData.keyView = 'etat_nominatif';
            saveData(JSON.parse(JSON.stringify(localData))); // on enregistre

            // setAspectFinancierid(response.data.datas.aspectFinancierId);
              // on notifie 
          notification.open({
            message:(
               <div style={{ color: 'white' }}>
                 Enregistrer avec success
               </div>
              ),
            description: '',
            placement:'bottomLeft', 
            icon: <CheckCircleOutlined />,
            style: {
                backgroundColor: 'green', // Couleur de fond personnalisée
                color:'white',
                // Autres styles personnalisés...
              },
          })

          setMainView('etat_nominatif');
            
          });

          setLoading(false);


        } catch (error) {
          console.error('Erreur lors de l\'enregistrement des données', error);
          setLoading(false);
        }
      };


   /* const handleNext = () => {
        setLoading(true);
        axios.post(APP_URL+'esb/save-recette/'+datas.rentreer_esb,{
            'fraisInscription':fraisInscription,
            'ecolage':ecolage,
            'subvention':subvention,
            'autreResource':autreResource,
            'investissement':investissement,
            'fonctionnement':fonctionnement
        },config).then((response) => {

            let localData  = readData(); // on recupere les donnees de la memoire local
            localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
            localData.keyView = 'etat_nominatif';
            saveData(JSON.parse(JSON.stringify(localData))); // on enregistre

            setAspectFinancierid(response.data.datas.aspectFinancierId);
            
             // on notifie 
          notification.open({
            message:(
               <div style={{ color: 'white' }}>
                 Enregistrer avec success
               </div>
              ),
            description: '',
            placement:'bottomLeft', 
            icon: <CheckCircleOutlined />,
            style: {
                backgroundColor: 'green', // Couleur de fond personnalisée
                color:'white',
                // Autres styles personnalisés...
              },
          })

          setMainView('etat_nominatif');

        }).catch((error) => {
            setLoading(false);
            console.log(error);
        })
    } */

    return (
        <>
<div class="container-fluid">

<button style={{width:'20%'}} onClick={() => { 

setRelationId(datas.rentreer_esb); //on passe l'ID du rapport
setMainView('aspects_finanncier'); // on passe la cle de la vue a retourner

}}  className="btn btn-success" >
<DoubleLeftOutlined />
&nbsp;&nbsp;
 retour
 
</button>

<br /><br />
<div class="card mb-3">
    <div class="card-body">
        <h2> Budget previsionnel en recettes et depenses </h2>
    </div>
</div>

 <div class="card mb-3">
    <div class="card-body">
    <table className="table" border={1}>
        <thead>
          <tr>
            <th>Libellé des opérations</th>
            <th>Montant Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan="2">A - RECETTES / INCOMES</th>
          </tr>
          {recettes.map((item, index) => (
            <tr key={index}>
              <td>{item.description}</td>
              <td>
                <input
                  type="number"
                  value={item.montant}
                  onChange={(e) => handleRecetteChange(index, e.target.value)}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td>TOTAL A</td>
            <td>{totalRecettes}</td>
          </tr>
          <tr>
            <th colSpan="2">B - DEPENSES / EXPENDITURES</th>
          </tr>
          {depenses.map((item, index) => (
            <tr key={index}>
              <td>{item.description}</td>
              <td>
                <input
                  type="number"
                  value={item.montant}
                  onChange={(e) => handleDepenseChange(index, e.target.value)}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td>TOTAL B</td>
            <td>{totalDepenses}</td>
          </tr>
          <tr>
            <td>BALANCE</td>
            <td>{balance}</td>
          </tr>
        </tbody>
      </table>
      <button onClick={handleSubmit} disabled={loading}>
        {loading?<>
            Enregistrement en cours
        </>:<>
            Suivant 
        </>}
      </button>
    </div>
 </div>
</div>
<br /><br /><br />
        </>
    );
}