import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Button, notification } from "antd";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { CheckCircleOutlined, DoubleLeftOutlined } from "@ant-design/icons";


export default function Administration(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };

    // const [denomination, setDenomination] = useState('');
    const [nomPrenomDirecteur, setNomPrenomDirecteur] = useState('');
    const [telDirecteur, setTelDirecteur] = useState('');
    const [qualificationDirecteur, setQualificationDirecteur] = useState('');
    const [datePriseFonctionDirecteur, setDatePriseFonctionDirecteur] = useState('');
    const [numeroAgrementDirecteur, setNumeroAgrementDirecteur] = useState('');

    const [nomPrenomDirecteurAdjoin, setNomPrenomDirecteurAdjoin] = useState('');
    const [telDirecteurAdjoin, setTelDirecteurAdjoin] = useState('');
    const [qualificationDirecteurAdjoin, setQualificationDirecteurAdjoin] = useState('');
    const [datePriseFonctionDirecteurAdjoin, setDatePriseFonctionDirecteurAdjoin] = useState('');
    const [numeroAgrementDirecteurAdjoin, setNumeroAgrementDirecteurAdjoin] = useState('');

    const [nomPrenomSecretaire, setNomPrenomSecretaire] = useState('');
    const [telSecretaire, setTelSecretaire] = useState('');
    const [qualificationSecretaire, setQualificationSecretaire] = useState('');
    const [datePriseFonctionSecretaire, setDatePriseFonctionSecretaire] = useState('');
    const [numeroAgrementSecretaire, setNumeroAgrementSecretaire] = useState('');

    const [nomPrenomAgentFinancier, setNomPrenomAgentFinancier] = useState('');
    const [telAgentFinancier, setTelAgentFinancier] = useState('');
    const [qualificationAgentFinancier, setQualificationAgentFinancier] = useState('');
    const [datePriseFonctionAgentFinancier, setDatePriseFonctionAgentFinancier] = useState('');
    const [numeroAgrementAgentFinancier, setNumeroAgrementAgentFinancier] = useState('');

    const [administration, setAdministration] = useState([
        { fonction: 'Directeur', name: '', phone: '', qualification: '', startDate: '', agreementNumber: '' },
        { fonction: 'Directeur Adjoint', name: '', phone: '', qualification: '', startDate: '', agreementNumber: '' },
        { fonction: 'Secrétaire', name: '', phone: '', qualification: '', startDate: '', agreementNumber: '' },
        { fonction: 'Agent financier', name: '', phone: '', qualification: '', startDate: '', agreementNumber: '' },
        { fonction: 'Autres', name: '', phone: '', qualification: '', startDate: '', agreementNumber: '' },
    ]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    },[]);

    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const updatedAdministration = administration.map((admin, i) => 
            i === index ? { ...admin, [name]: value } : admin
        );
        setAdministration(updatedAdministration);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // fetch(APP_URL+'save-new-admin/'+datas.rentreer_esb, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(administration)
        // })
        // .then(response => response.json())
        // .then(data => console.log(data))
        // .catch(error => console.error('Error:', error));
        setLoading(true);
    
        try {
            await axios.post(APP_URL+'save-new-admin/'+datas.rentreer_esb, administration,config);
           
        setLoading(false);
        notification.open({
            message:(
               <div style={{ color: 'white' }}>
                  Enregistrer avec success
               </div>
              ),
            description: '',
            placement:'bottomLeft', 
            icon: <CheckCircleOutlined />,
            style: {
                backgroundColor: 'green', // Couleur de fond personnalisée
                color:'white',
                // Autres styles personnalisés...
              },
          })

          setLoading(false);

          let localData  = readData(); // on recupere les donnees de la memoire local
          localData.keyView = 'deroulement_rentree'; // on enregistre la vue en cours

          saveData(JSON.parse(JSON.stringify(localData)));

          setMainView('deroulement_rentree');

        } catch (error) {
            console.log(error);
            setLoading(false);
        }

    };

    /*const handleSubmit = () => {
        setLoading(true);
        axios.post(APP_URL+'esb/save-administration',{
            'nomPrenomDirecteur':nomPrenomDirecteur,
            'telDirecteur':telDirecteur,
            'qualificationDirecteur':qualificationDirecteur,
            'datePriseFonctionDirecteur':datePriseFonctionDirecteur,
            'numeroAgrementDirecteur':numeroAgrementDirecteur,

            'nomPrenomDirecteurAdjoin':nomPrenomDirecteurAdjoin,
            'telDirecteurAdjoin':telDirecteurAdjoin,
            'qualificationDirecteurAdjoin':qualificationDirecteurAdjoin,
            'datePriseFonctionDirecteurAdjoin':datePriseFonctionDirecteurAdjoin,
            'numeroAgrementDirecteurAdjoin':numeroAgrementDirecteurAdjoin,
            
            'nomPrenomSecretaire':nomPrenomSecretaire,
            'telSecretaire':telSecretaire,
            'qualificationSecretaire':qualificationSecretaire,
            'datePriseFonctionSecretaire':datePriseFonctionSecretaire,
            'numeroAgrementSecretaire':numeroAgrementSecretaire,

            'nomPrenomAgentFinancier':nomPrenomAgentFinancier,
            'telAgentFinancier':telAgentFinancier,
            'qualificationAgentFinancier':qualificationAgentFinancier,
            'datePriseFonctionAgentFinancier':datePriseFonctionAgentFinancier,
            'numeroAgrementAgentFinancier':numeroAgrementAgentFinancier,
            'rentreer_esb' : datas.rentreer_esb,

        },config).then((response) => {
            setLoading(false);

            let localData  = readData(); // on recupere les donnees de la memoire local
            localData.relationId = response.data.infrastructure_id; // on enregistre la vue en cours
            localData.keyView = 'deroulement_rentree';
            saveData(JSON.parse(JSON.stringify(localData))); // on enregistre

              // on notifie 
              notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

              setMainView('deroulement_rentree');

        }).catch((error) => {
            setLoading(false);
            console.log(error);
        })
    } */

    return (
        <>
         <div class="container-fluid">

<button style={{width:'20%'}} onClick={() => { 

setRelationId(datas.rentreer_esb); //on passe l'ID du rapport
setMainView('type_sale'); // on passe la cle de la vue a retourner

}}  className="btn btn-success" >
<DoubleLeftOutlined />
&nbsp;&nbsp;
 retour
 
</button>

<br /><br />

                <div class="card mb-3">
                    <div class="card-body">
                        <h2> Administration </h2>
                    </div>
                </div>

            <div class="card mb-3">
                <div class="card-body">
                <form onSubmit={handleSubmit}>
            <table className="table" border={1}>
                <thead>
                    <tr>
                        <th>Fonction</th>
                        <th>Noms et Prénoms</th>
                        <th>N° Téléphone</th>
                        <th>Qualification</th>
                        <th>Date de prise de fonction</th>
                        <th>N° d'agrément</th>
                    </tr>
                </thead>
                <tbody>
                    {administration.map((admin, index) => (
                        <tr key={index}>
                            <td>{admin.fonction}</td>
                            <td><input type="text" name="name" value={admin.name} onChange={(e) => handleChange(index, e)} /></td>
                            <td><input type="text" name="phone" value={admin.phone} onChange={(e) => handleChange(index, e)} /></td>
                            <td><input type="text" name="qualification" value={admin.qualification} onChange={(e) => handleChange(index, e)} /></td>
                            <td><input type="date" name="startDate" value={admin.startDate} onChange={(e) => handleChange(index, e)} /></td>
                            <td><input type="text" name="agreementNumber" value={admin.agreementNumber} onChange={(e) => handleChange(index, e)} /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button disabled={loading} type="submit">
                {loading?<>
                    Enregistrement en cours
                </>:<>
                    Suivant
                </>}
            </button>
        </form>
                </div>
            </div>
        </div>
           <br /><br />
        </>
    );
}