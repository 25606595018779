import { useRecoilState } from "recoil";
import { aspectsFinanciersIdState, mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Button, notification } from "antd";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { CheckCircleOutlined, DoubleLeftOutlined } from "@ant-design/icons";


export default function ContributionExigibleVerse(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
    const [aspectFinancierId, setAspectFinancierid] = useRecoilState(aspectsFinanciersIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();

    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };

    const [seduc, setSeduc] = useState(0);
    const [fenasco, setFenasco] = useState(0);
    const [assurance, setAssurance] = useState(0);
    const [fass, setFass] = useState(0);
    const [cnps, setCnps] = useState(0);
    const [impotsTaxe, setImpotTaxe] = useState(0);

    const handleNext = () => {
        setLoading(true);

        axios.post(APP_URL+'esb/save-contribution/'+datas.rentreer_esb,{
            'seduc':seduc,
            'fenasco':fenasco,
            'assurance':assurance,
            'fass':fass,
            'cnps':cnps,
            'impotsTaxe':impotsTaxe,
            'aspectFinancier': aspectFinancierId,
        },config).then((response) => {

            let localData  = readData(); // on recupere les donnees de la memoire local
            localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
            localData.keyView = 'recette';
            saveData(JSON.parse(JSON.stringify(localData))); // on enregistre

            setAspectFinancierid(response.data.datas.aspectFinancierId);
            
             // on notifie 
          notification.open({
            message:(
               <div style={{ color: 'white' }}>
                 Enregistrer avec success
               </div>
              ),
            description: '',
            placement:'bottomLeft', 
            icon: <CheckCircleOutlined />,
            style: {
                backgroundColor: 'green', // Couleur de fond personnalisée
                color:'white',
                // Autres styles personnalisés...
              },
          })

          setMainView('recette');


        }).catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    return (
        <>
<div class="container-fluid">

<button style={{width:'20%'}} onClick={() => { 

setRelationId(datas.rentreer_esb); //on passe l'ID du rapport
setMainView('aspects_finanncier'); // on passe la cle de la vue a retourner

}}  className="btn btn-success" >
<DoubleLeftOutlined />
&nbsp;&nbsp;
 retour
 
</button>

<br /><br />
<div class="card mb-3">
    <div class="card-body">
        <h2> Contributions exigibles deja versees (montant) </h2>
    </div>
</div>

 <div class="card mb-3">
    <div class="card-body">
        
    <h6>SEDUC</h6>
        <input type="text" className="form-control" value={seduc} onChange={(e) => setSeduc(e.target.value)} />
        <br />

        <h6>FENASCO</h6>
        <input type="text" className="form-control" value={fenasco} onChange={(e) => setFenasco(e.target.value)} />
        <br />

        <h6>Assurance</h6>
        <input type="text" className="form-control" value={assurance} onChange={(e) => setAssurance(e.target.value)} />
        <br />

        <h6>FASS</h6>
        <input type="text" className="form-control" value={fass} onChange={(e) => setFass(e.target.value)} />
        <br />

        <h6>CNPS</h6>
        <input type="text" className="form-control" value={cnps} onChange={(e) => setCnps(e.target.value)} />
        <br />

        <h6>Impots et taxes</h6>
        <input type="text" className="form-control" value={impotsTaxe} onChange={(e) => setImpotTaxe(e.target.value)} />
        <br />

        <br />
        <Button className="btn btn-primary" onClick={handleNext} loading={loading}>Suivant</Button>

    </div>
</div>
</div>
<br /><br /><br />
        </>
    );
}