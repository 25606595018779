import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Button, notification } from "antd";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { CheckCircleOutlined, DoubleLeftOutlined } from "@ant-design/icons";


export default function Synthese_effectifs(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const date = new Date();
    const year = date.getFullYear();

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };

        const [maternelGacon, setMaternelGarcon] = useState('');
        const [maternelFille, setMaternelFille] = useState('');
        
        const [silGarcon, setSilGarcon] = useState('');
        const [silFille, setSilFille] = useState('');

        const [cpGarcon, setCpGarcon] = useState('');
        const [cpFille, setCpFille] = useState('');

        const [ce1Garcon, setCe1Garcon] = useState('');
        const [ce1Fille, setCe1Fille] = useState('');

        const [ce2Garcon, setCe2Garcon] = useState('');
        const [ce2Fille, setCe2Fille] = useState('');
        
        const [cm1Garcon, setCm1Garcon] = useState('');
        const [cm1Fille, setCm1Fille] = useState('');

        const [cm2Garcon, setCm2Garcon] = useState('');
        const [cm2Fille, setCm2Fille] = useState('')

  
    const initialEffectifs = [
        { classe: 'MAT-NURS', effectif: { G: 0, F: 0, total: 0 } },
        { classe: 'SIL-CL1', effectif: { G: 0, F: 0, total: 0 } },
        { classe: 'CP-CL2', effectif: { G: 0, F: 0, total: 0 } },
        { classe: 'CE1-CL3', effectif: { G: 0, F: 0, total: 0 } },
        { classe: 'CE2-CL4', effectif: { G: 0, F: 0, total: 0 } },
        { classe: 'CM1-CL5', effectif: { G: 0, F: 0, total: 0 } },
        { classe: 'CM2-CL6', effectif: { G: 0, F: 0, total: 0 } },
    ];

    const [effectifs, setEffectifs] = useState(initialEffectifs);

    useEffect(() => {
        // window.scrollTo({ top: 0, behavior: "smooth" });
        calculateTotal();
    },[effectifs]);

    const handleChange = (index, subField, value) => {
        const updatedEffectifs = effectifs.map((eff, i) => {
            if (i === index) {
                const newEffectif = { ...eff.effectif, [subField]: parseInt(value) || 0 };
                newEffectif.total = newEffectif.G + newEffectif.F;
                return { ...eff, effectif: newEffectif };
            }
            return eff;
        });
        setEffectifs(updatedEffectifs);
    };

    const calculateTotal = () => {
        const totalEffectif = effectifs.reduce((acc, curr) => {
            acc.G += curr.effectif.G;
            acc.F += curr.effectif.F;
            acc.total += curr.effectif.total;
            return acc;
        }, { G: 0, F: 0, total: 0 });
        setTotal(totalEffectif);
    };

    const [total, setTotal] = useState({ G: 0, F: 0, total: 0 });
    
    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        axios.post(APP_URL+'save-synthese-effectif/'+datas.rentreer_esb,effectifs,config).then((response) => {
           
            setLoading(false);

            let localData  = readData(); // on recupere les donnees de la memoire local
            localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
            localData.keyView = 'repartition_effectifs_trois_annees';
            saveData(JSON.parse(JSON.stringify(localData))); // on enregistre

            // on notifie 
          notification.open({
            message:(
               <div style={{ color: 'white' }}>
                 Enregistrer avec success
               </div>
              ),
            description: '',
            placement:'bottomLeft', 
            icon: <CheckCircleOutlined />,
            style: {
                backgroundColor: 'green', // Couleur de fond personnalisée
                color:'white',
                // Autres styles personnalisés...
              },
          })

          setMainView('repartition_effectifs_trois_annees');

        }).catch((error) => {
            setLoading(false);
            console.log(error);
        })
        // fetch('/api/synthese_effectifs', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(effectifs)
        // })
        // .then(response => response.json())
        // .then(data => console.log(data))
        // .catch(error => console.error('Error:', error));
    };

    /*const handleNext = () => {
        
        setLoading(true);
        axios.post(APP_URL+'esb/save-synthese-effectif',{
            'rentreer_esb' : datas.rentreer_esb,
            'maternelGacon' : maternelGacon,
            'maternelFille':maternelFille,
            'silGarcon':silGarcon,
            'silFille':silFille,
            'cpGarcon':cpGarcon,
            'cpFille':cpFille,
            'ce1Garcon':ce1Garcon,
            'ce1Fille':ce1Fille,
            'ce2Garcon':ce2Garcon,
            'ce2Fille':ce2Fille,
            'cm1Garcon':cm1Garcon,
            'cm1Fille':cm1Fille,
            'cm2Garcon':cm2Garcon,
            'cm2Fille':cm2Fille,
        },config).then((response) => {

            setLoading(false);

            let localData  = readData(); // on recupere les donnees de la memoire local
            localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
            localData.keyView = 'etablissement_environnants';
            saveData(JSON.parse(JSON.stringify(localData))); // on enregistre

            // on notifie 
          notification.open({
            message:(
               <div style={{ color: 'white' }}>
                 Enregistrer avec success
               </div>
              ),
            description: '',
            placement:'bottomLeft', 
            icon: <CheckCircleOutlined />,
            style: {
                backgroundColor: 'green', // Couleur de fond personnalisée
                color:'white',
                // Autres styles personnalisés...
              },
          })

          setMainView('etablissement_environnants');

        }).catch((error) => {
            setLoading(false);
            console.log(error);
        })
    } */

    return (
        <>
<div class="container-fluid">

<button style={{width:'20%'}} onClick={() => { 

setRelationId(datas.rentreer_esb); //on passe l'ID du rapport
setMainView('repartition_effectif_ango'); // on passe la cle de la vue a retourner

}}  className="btn btn-success" >
<DoubleLeftOutlined />
&nbsp;&nbsp;
 retour
 
</button>

<br /><br />
<div class="card mb-3">
    <div class="card-body">
        <h2> Synthese des effectifs</h2>
    </div>
</div>

<form onSubmit={handleSubmit}>
            <table className="table" border={1}>
                <thead>
                    <tr>
                        <th>CLASSES</th>
                        <th>Effectif (G)</th>
                        <th>Effectif (F)</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {effectifs.map((eff, index) => (
                        <tr key={index}>
                            <td>{eff.classe}</td>
                            <td><input type="number" value={eff.effectif.G} onChange={(e) => handleChange(index, 'G', e.target.value)} /></td>
                            <td><input type="number" value={eff.effectif.F} onChange={(e) => handleChange(index, 'F', e.target.value)} /></td>
                            <td><input type="number" value={eff.effectif.total} readOnly /></td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th>Total</th>
                        <th>{total.G}</th>
                        <th>{total.F}</th>
                        <th>{total.total}</th>
                    </tr>
                </tfoot>
            </table>
            <button disabled={loading} type="submit">
                {loading?<>
                    Enregistrement en cours..
                </>:<>
                    Suivant
                </>}
            </button>
        </form>
        
 </div>
 <br /><br /><br />
        </>
    );
}