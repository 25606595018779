import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { Button, notification } from "antd";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { CheckCircleOutlined, DoubleLeftOutlined } from "@ant-design/icons";


export default function Deroulement_rentree(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };

    const [preparatif, setPreparatif] = useState('');
    const [recrutement, setRecrutement] = useState('');
    const [pb_rencontre, setPbRencontre] = useState('');
    const [comment_resoudre, setCommentResoudre] = useState('');
   
        useEffect(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },[]);
    
        const handleNext = () => {
            setLoading(true);

            axios.post(APP_URL+'esb/save-deroulement-rentree',{
                'preparatif':preparatif,
                'recrutement':recrutement,
                'pb_rencontre':pb_rencontre,
                'comment_resoudre':comment_resoudre,
                'rentreer_esb' : datas.rentreer_esb,
            },config).then((response) => {
                setLoading(false);

                let localData  = readData(); // on recupere les donnees de la memoire local
                localData.relationId = null; // on enregistre la vue en cours
                localData.keyView = 'repartition_effectifs';
                saveData(JSON.parse(JSON.stringify(localData))); // on enregistre
    
                // on notifie 
              notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

              setMainView('repartition_effectifs');

            }).catch((error) => {
                setLoading(false);
                console.log(error); 
            })
        }

    return (
        <>
           <div class="container-fluid">

<button style={{width:'20%'}} onClick={() => { 

setRelationId(datas.rentreer_esb); //on passe l'ID du rapport
setMainView('administration'); // on passe la cle de la vue a retourner

}}  className="btn btn-success" >
<DoubleLeftOutlined />
&nbsp;&nbsp;
 retour
 
</button>

<br /><br />
<div class="card mb-3">
    <div class="card-body">
        <h2> Déroulement de la rentrée </h2>
    </div>
</div>

 <div class="card mb-3">
    <div class="card-body">
        <h6>Comment avez-vous préparé la rentrée scolaire(réunions, seminaires, etc)</h6>
        <textarea className="form-control" onChange={(e) => setPreparatif(e.target.value)}>{preparatif}</textarea>
        <br /><br />
        <h6>Comment se sont effectués les recrutements des élèves et des enseignants(difficutés éventuelles rencontrées)</h6>
        <textarea className="form-control" onChange={(e) => setRecrutement(e.target.value)}>{recrutement}</textarea>
        
        <br /><br />
        <h6>Quels sont les problème rencontrés</h6>
        <textarea className="form-control" onChange={(e) => setPbRencontre(e.target.value)}>{pb_rencontre}</textarea>

        <br /><br />
        <h6>Comment envisagez vous les résoudre ?</h6>
        <textarea className="form-control" onChange={(e) => setCommentResoudre(e.target.value)}>{comment_resoudre}</textarea>
        <br />
        <Button className="btn btn-primary" loading={loading} onClick={handleNext}> Suivant </Button>
    </div>
 </div>
 <br /><br /> <br /><br />
</div>
        </>
    );
}