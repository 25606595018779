import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { Button, notification } from "antd";
import { CheckCircleOutlined, DoubleLeftOutlined, WarningOutlined } from "@ant-design/icons";


export default function Repartition_effectifs(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const date = new Date();
    const year = date.getFullYear();

    const [nbGarconRentreePetite, setNbGaconRentreePetite] = useState('');
    const [nbFilleRentreePetite, setNbFilleRentreePetite] = useState('');
    const [nbGarconRedoublantPetite, setNbGarconeRedoublantPetite] = useState('');
    const [nbFilleRedoublantePetite, setNbFilleRedoublantePetite] = useState('');

    const [nbGarconRentreeGrande, setNbGaconRentreeGrande] = useState('');
    const [nbFilleRentreeGrande, setNbFilleRentreeGrande] = useState('');
    const [nbGarconRedoublantGrande, setNbGarconeRedoublantGrande] = useState('');
    const [nbFilleRedoublanteGrande, setNbFilleRedoublanteGrande] = useState('');

    const [nbGarconRentreeSil, setNbGaconRentreeSil] = useState('');
    const [nbFilleRentreeSil, setNbFilleRentreeSil] = useState('');
    const [nbGarconRedoublantSil, setNbGarconeRedoublantSil] = useState('');
    const [nbFilleRedoublanteSil, setNbFilleRedoublanteSil] = useState('');

    const [nbGarconRentreeCp, setNbGaconRentreeCp] = useState('');
    const [nbFilleRentreeCp, setNbFilleRentreeCp] = useState('');
    const [nbGarconRedoublantCp, setNbGarconeRedoublantCp] = useState('');
    const [nbFilleRedoublanteCp, setNbFilleRedoublanteCp] = useState('');

    const [nbGarconRentreeCe1, setNbGaconRentreeCe1] = useState('');
    const [nbFilleRentreeCe1, setNbFilleRentreeCe1] = useState('');
    const [nbGarconRedoublantCe1, setNbGarconeRedoublantCe1] = useState('');
    const [nbFilleRedoublanteCe1, setNbFilleRedoublanteCe1] = useState('');

    const [nbGarconRentreeCe2, setNbGaconRentreeCe2] = useState('');
    const [nbFilleRentreeCe2, setNbFilleRentreeCe2] = useState('');
    const [nbGarconRedoublantCe2, setNbGarconeRedoublantCe2] = useState('');
    const [nbFilleRedoublanteCe2, setNbFilleRedoublanteCe2] = useState('');

    const [nbGarconRentreeCM1, setNbGaconRentreeCM1] = useState('');
    const [nbFilleRentreeCM1, setNbFilleRentreeCM1] = useState('');
    const [nbGarconRedoublantCM1, setNbGarconeRedoublantCM1] = useState('');
    const [nbFilleRedoublanteCM1, setNbFilleRedoublanteCM1] = useState('');

    const [nbGarconRentreeCM2, setNbGaconRentreeCM2] = useState('');
    const [nbFilleRentreeCM2, setNbFilleRentreeCM2] = useState('');
    const [nbGarconRedoublantCM2, setNbGarconeRedoublantCM2] = useState('');
    const [nbFilleRedoublanteCM2, setNbFilleRedoublanteCM2] = useState(''); 

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };

        const initialEffectifs = [
            { classe: 'PS', eleves: { G: 0, F: 0, total: 0 }, redoublants: { G: 0, F: 0, total: 0, pourcentage: 0 } },
            { classe: 'GS', eleves: { G: 0, F: 0, total: 0 }, redoublants: { G: 0, F: 0, total: 0, pourcentage: 0 } },
            { classe: 'SIL', eleves: { G: 0, F: 0, total: 0 }, redoublants: { G: 0, F: 0, total: 0, pourcentage: 0 } },
            { classe: 'CP', eleves: { G: 0, F: 0, total: 0 }, redoublants: { G: 0, F: 0, total: 0, pourcentage: 0 } },
            { classe: 'CE1', eleves: { G: 0, F: 0, total: 0 }, redoublants: { G: 0, F: 0, total: 0, pourcentage: 0 } },
            { classe: 'CE2', eleves: { G: 0, F: 0, total: 0 }, redoublants: { G: 0, F: 0, total: 0, pourcentage: 0 } },
            { classe: 'CM1', eleves: { G: 0, F: 0, total: 0 }, redoublants: { G: 0, F: 0, total: 0, pourcentage: 0 } },
            { classe: 'CM2', eleves: { G: 0, F: 0, total: 0 }, redoublants: { G: 0, F: 0, total: 0, pourcentage: 0 } },
        ];

        const [effectifs, setEffectifs] = useState(initialEffectifs);

        const handleChange = (index, field, subField, value) => {
            const updatedEffectifs = effectifs.map((eff, i) => {
                if (i === index) {
                    const newField = { ...eff[field], [subField]: value };
                    if (subField === 'G' || subField === 'F') {
                        newField.total = parseInt(newField.G || 0) + parseInt(newField.F || 0);
                    }
                    if (field === 'redoublants') {
                        newField.pourcentage = (newField.total / eff.eleves.total) * 100 || 0;
                    }
                    return { ...eff, [field]: newField };
                }
                return eff;
            });
            setEffectifs(updatedEffectifs);
        };
    

        const handleSubmit = async (e) => {
            e.preventDefault();
           
            setLoading(true);

            try {
                await axios.post(APP_URL+'save-new-repartition-effectif-franco/'+datas.rentreer_esb, effectifs,config);

                setLoading(false);
                notification.open({
                    message:(
                       <div style={{ color: 'white' }}>
                          Enregistrer avec success
                       </div>
                      ),
                    description: '',
                    placement:'bottomLeft', 
                    icon: <CheckCircleOutlined />,
                    style: {
                        backgroundColor: 'green', // Couleur de fond personnalisée
                        color:'white',
                        // Autres styles personnalisés...
                      },
                  })

                  setLoading(false);

                  let localData  = readData(); // on recupere les donnees de la memoire local
                  localData.keyView = 'repartition_effectif_ango'; // on enregistre la vue en cours
        
                  saveData(JSON.parse(JSON.stringify(localData)));
        
                  setMainView('repartition_effectif_ango');

            }catch (error) {
                console.log(error);
                setLoading(false);
            }
        };

        /* const handleNext = () => {

            setLoading(true);

            axios.post(APP_URL + 'esb/save-repartition',{
                'rentreer_esb' : datas.rentreer_esb,
            },config).then((response) => {
    
                setLoading(false);
                setRelationId(response.data.repartition_id);
                
                let localData  = readData(); // on recupere les donnees de la memoire local
                localData.relationId = response.data.repartition_id; // on enregistre l'id de la repartition des effectifs
               
            }).catch((error) => {
                console.log(error);
                setLoading(false);
            });
    
           if(

            nbGarconRentreePetite != '' &&
            nbFilleRentreePetite != '' &&
            nbGarconRedoublantPetite != '' &&
            nbFilleRedoublantePetite != '' &&
            nbGarconRentreeGrande != '' &&
            nbFilleRentreeGrande != '' &&
            nbGarconRedoublantGrande != '' &&
            nbFilleRedoublanteGrande != '' &&
            nbGarconRentreeSil != '' &&
            nbFilleRentreeSil != '' &&
            nbGarconRedoublantSil != '' &&
            nbFilleRedoublanteSil != '' &&
            nbGarconRentreeCp != '' &&
            nbFilleRentreeCp != '' &&
            nbGarconRedoublantCp != '' &&
            nbFilleRedoublanteCp != '' &&
            nbGarconRentreeCe1 != '' &&
            nbFilleRentreeCe1 != '' &&
            nbGarconRedoublantCe1 != '' &&
            nbFilleRedoublanteCe1 != '' &&
            nbGarconRentreeCe2 != '' &&
            nbFilleRentreeCe2 != '' &&
            nbGarconRedoublantCe2 != '' &&
            nbFilleRedoublanteCe2 != '' &&
            nbGarconRentreeCM1 != '' &&
            nbFilleRentreeCM1 != '' &&
            nbGarconRedoublantCM1 != '' &&
            nbFilleRedoublanteCM1 != '' &&
            nbGarconRentreeCM2 != '' &&
            nbFilleRentreeCM2 != '' &&
            nbGarconRedoublantCM2 != '' &&
            nbFilleRedoublanteCM2 != '' 

           ){

            axios.post(APP_URL+'esb/save-section',{
                'nbGarconRentreePetite':nbGarconRentreePetite,
                'nbFilleRentreePetite':nbFilleRentreePetite,
                'nbGarconRedoublantPetite':nbGarconRedoublantPetite,
                'nbFilleRedoublantePetite':nbFilleRedoublantePetite,

                'nbGarconRentreeGrande':nbGarconRentreeGrande,
                'nbFilleRentreeGrande':nbFilleRentreeGrande,
                'nbGarconRedoublantGrande':nbGarconRedoublantGrande,
                'nbFilleRedoublanteGrande':nbFilleRedoublanteGrande,

                'nbGarconRentreeSil':nbGarconRentreeSil,
                'nbFilleRentreeSil':nbFilleRentreeSil,
                'nbGarconRedoublantSil':nbGarconRedoublantSil,
                'nbFilleRedoublanteSil':nbFilleRedoublanteSil,

                'nbGarconRentreeCp':nbGarconRentreeCp,
                'nbFilleRentreeCp':nbFilleRentreeCp,
                'nbGarconRedoublantCp':nbGarconRedoublantCp,
                'nbFilleRedoublanteCp':nbFilleRedoublanteCp,

                'nbGarconRentreeCe1':nbGarconRentreeCe1,
                'nbFilleRentreeCe1':nbFilleRentreeCe1,
                'nbGarconRedoublantCe1':nbGarconRedoublantCe1,
                'nbFilleRedoublanteCe1':nbFilleRedoublanteCe1,

                'nbGarconRentreeCe2':nbGarconRentreeCe2,
                'nbFilleRentreeCe2':nbFilleRentreeCe2,
                'nbGarconRedoublantCe2':nbGarconRedoublantCe2,
                'nbFilleRedoublanteCe2':nbFilleRedoublanteCe2,

                'nbGarconRentreeCm1':nbGarconRentreeCM1,
                'nbFilleRentreeCm1':nbFilleRentreeCM1,
                'nbGarconRedoublantCm1':nbGarconRedoublantCM1,
                'nbFilleRedoublanteCm1':nbFilleRedoublanteCM1,

                'nbGarconRentreeCm2':nbGarconRentreeCM2,
                'nbFilleRentreeCm2':nbFilleRentreeCM2,
                'nbGarconRedoublantCm2':nbGarconRedoublantCM2,
                'nbFilleRedoublanteCm2':nbFilleRedoublanteCM2,

                'rentreer_esb' : datas.rentreer_esb,
                'repartition_effectif_id' : relationId,

            },config).then((response) => {

                setLoading(false);
                let localData  = readData(); // on recupere les donnees de la memoire local
                localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
                localData.keyView = 'synthese_effectifs';
                saveData(JSON.parse(JSON.stringify(localData))); // on enregistre
    
                // on notifie 
              notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

              setMainView('synthese_effectifs');


            }).catch((error) => {

                console.log(error);
                setLoading(false);

            })

          }else{

            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     un ou plueieurs champs sont manquants dans le formulaire.
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <WarningOutlined />,
                style: {
                    backgroundColor: 'red', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

          }
        }   */

    useEffect(() => {

        window.scrollTo({ top: 0, behavior: "smooth" });
       
    },[]);

    return (
        <>
<div class="container-fluid">

<button style={{width:'20%'}} onClick={() => { 

setRelationId(datas.rentreer_esb); //on passe l'ID du rapport
setMainView('deroulement_rentree'); // on passe la cle de la vue a retourner

}}  className="btn btn-success" >
<DoubleLeftOutlined />
&nbsp;&nbsp;
 retour
 
</button>

<br /><br />
<div class="card mb-3">
    <div class="card-body">
        <h2> Repartition des effectifs | Section Francophone {year}/{year+1}</h2>
    </div>
</div>

 <div class="card mb-3">
    <div class="card-body">

    <form onSubmit={handleSubmit}>
            <table className="table" border={1}>
                <thead>
                    <tr>
                        <th>CLASSES</th>
                        <th>Élèves Inscrits à la rentrée (G)</th>
                        <th>Élèves Inscrits à la rentrée (F)</th>
                        <th>Élèves Inscrits à la rentrée (Total)</th>
                        <th>Redoublants (G)</th>
                        <th>Redoublants (F)</th>
                        <th>Redoublants (Total)</th>
                        <th>Redoublants (%)</th>
                    </tr>
                </thead>
                <tbody>
                    {effectifs.map((eff, index) => (
                        <tr key={index}>
                            <td>{eff.classe}</td>
                            <td><input type="number" value={eff.eleves.G} onChange={(e) => handleChange(index, 'eleves', 'G', e.target.value)} /></td>
                            <td><input type="number" value={eff.eleves.F} onChange={(e) => handleChange(index, 'eleves', 'F', e.target.value)} /></td>
                            <td style={{background:'gray'}}><input type="number" value={eff.eleves.total} readOnly /></td>
                            <td><input type="number" value={eff.redoublants.G} onChange={(e) => handleChange(index, 'redoublants', 'G', e.target.value)} /></td>
                            <td><input type="number" value={eff.redoublants.F} onChange={(e) => handleChange(index, 'redoublants', 'F', e.target.value)} /></td>
                            <td style={{background:'gray'}}><input type="number" value={eff.redoublants.total} readOnly /></td>
                            <td style={{background:'gray'}}><input type="number" value={eff.redoublants.pourcentage.toFixed(2)} readOnly /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button type="submit" disabled={loading}>
                {loading?<>
                    Enregistrement en cours
                </>:<>
                    Suivant
                </>}
            </button>
        </form>

    </div>
   </div>

 <br /><br />
</div>
        </>
    );
}