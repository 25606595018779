import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useState } from "react";
import { Button, notification } from "antd";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { useCookies } from "react-cookie";
import { CheckCircleOutlined, DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";



export default function Trans1(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);

    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const [data, setData] = useState([
        { type: 'Permanent', level: 'Maternelle', male_count: 0, female_count: 0, total: 0, report_type: 'rentree' },
        { type: 'Vacataire', level: 'Maternelle', male_count: 0, female_count: 0, total: 0, report_type: 'rentree' },
        // Ajoute les autres lignes nécessaires ici
    ]);

    const handleChange = (index, field, value) => {
        const newData = [...data];
        newData[index][field] = value;
        if (field === 'male_count' || field === 'female_count') {
            newData[index].total = parseInt(newData[index].male_count) + parseInt(newData[index].female_count);
        }
        setData(newData);
    };

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };

        const handleSubmit = async (e) => {
            
            e.preventDefault();
            setLoading(true);
            let localData  = readData();

            console.log('ID',datas.rentreer_esb);
            try {
                await axios.post(APP_URL+'save-repartition-enseignant/'+datas.rentreer_esb, data,config);
               
                 
            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                      Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

              setLoading(false);

              let localData  = readData(); // on recupere les donnees de la memoire local
              localData.keyView = 'infrastructure'; // on enregistre la vue en cours

              saveData(JSON.parse(JSON.stringify(localData)));

              setMainView('infrastructure');

            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };

  


    return (
        <>
         <div class="container-fluid">

         <Button style={{width:'20%'}} onClick={() => { 
            setRelationId(datas.rentreer_esb);
            setMainView('etape1_esb_enseignement'); 

         }}  className="btn btn-success" >
            <DoubleLeftOutlined />
            &nbsp;&nbsp;
             retour
             
         </Button>

        <br /><br />
          <div class="card mb-3">
         
          <div class="card-body">
                <h2> Repartition des enseignants </h2>
          </div>
          </div>

          <div class="card mb-3">

          <div class="card-body">
          <form onSubmit={handleSubmit}>
            <table border={1} className="table" style={{width:'100%', padding:'1%'}}>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Niveau</th>
                        <th>H/M</th>
                        <th>F/F</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index}>
                            <td>{row.type}</td>
                            <td>{row.level}</td>
                            <td>
                                <input
                                    type="number"
                                    value={row.male_count}
                                    onChange={(e) => handleChange(index, 'male_count', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={row.female_count}
                                    onChange={(e) => handleChange(index, 'female_count', e.target.value)}
                                />
                            </td>
                            <td>{row.total}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button disabled={loading} type="submit">
                {loading?<>Enregistrement en cours</>:<>Suivant</>}
                &nbsp; <DoubleRightOutlined />
            </button>
        </form>

          </div>
          </div>
            <br /><br /><br />
         </div>
        </>
    );
}