import { useEffect, useState } from "react";
import { APP_URL } from "../../../../URL";
import { useRecoilState } from "recoil";
import { mainViewState, relationIdState, repartitionEffectifIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Button, notification } from "antd";
import { CheckCircleOutlined, DoubleLeftOutlined } from "@ant-design/icons";

const getInitialYears = () => {
    const currentYear = new Date().getFullYear();
    return [currentYear - 2, currentYear - 1, currentYear];
  };
  
  const getInitialData = (years) => {
    return [
      { classe: 'PS', [years[0]]: { G: 0, B: 0, total: 0 }, [years[1]]: { G: 0, B: 0, total: 0 }, [years[2]]: { G: 0, B: 0, total: 0 } },
      { classe: 'GS', [years[0]]: { G: 0, B: 0, total: 0 }, [years[1]]: { G: 0, B: 0, total: 0 }, [years[2]]: { G: 0, B: 0, total: 0 } },
      { classe: 'SIL', [years[0]]: { G: 0, B: 0, total: 0 }, [years[1]]: { G: 0, B: 0, total: 0 }, [years[2]]: { G: 0, B: 0, total: 0 } },
      { classe: 'CP', [years[0]]: { G: 0, B: 0, total: 0 }, [years[1]]: { G: 0, B: 0, total: 0 }, [years[2]]: { G: 0, B: 0, total: 0 } },
      { classe: 'CE1', [years[0]]: { G: 0, B: 0, total: 0 }, [years[1]]: { G: 0, B: 0, total: 0 }, [years[2]]: { G: 0, B: 0, total: 0 } },
      { classe: 'CE2', [years[0]]: { G: 0, B: 0, total: 0 }, [years[1]]: { G: 0, B: 0, total: 0 }, [years[2]]: { G: 0, B: 0, total: 0 } },
      { classe: 'CM1', [years[0]]: { G: 0, B: 0, total: 0 }, [years[1]]: { G: 0, B: 0, total: 0 }, [years[2]]: { G: 0, B: 0, total: 0 } },
      { classe: 'CM2', [years[0]]: { G: 0, B: 0, total: 0 }, [years[1]]: { G: 0, B: 0, total: 0 }, [years[2]]: { G: 0, B: 0, total: 0 } },
      
      // Ajoutez les autres classes ici
    ];
  };
  
export default function Repartition_effectifs_trois_annees(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const date = new Date();
    const year = date.getFullYear();

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };

  const initialYears = getInitialYears();
  const [years, setYears] = useState(initialYears);
  const [data, setData] = useState(getInitialData(initialYears));

  const handleChange = (year, classe, gender, value) => {
    const updatedData = data.map(item => {
      if (item.classe === classe) {
        item[year][gender] = parseInt(value, 10);
        item[year].total = item[year].G + item[year].B;
      }
      return item;
    });
    setData(updatedData);
  };
      

    const [repartitionEffectifsId, setRepartitionEffectifsId] = useRecoilState(repartitionEffectifIdState);
    
    useEffect(() => {

        // axios.post(APP_URL+'esb/save-trois-annee',{
        //     'rentreer_esb' : datas.rentreer_esb,
        // },config).then((response) => {
        //     setRepartitionEffectifsId(response.data.id)
        //     // setAnnee(response.data.annee1);
        // }).catch((error) => {
        //     console.log(error);
        // })

    },[]);

    const handleSubmit = async () => {
        setLoading(true);
        try {
          await axios.post(APP_URL+'save-new-repartition-effectif-annee-anglo/'+datas.rentreer_esb, data,config);
        //   etablissement_environnants
        setLoading(false);

        let localData  = readData(); // on recupere les donnees de la memoire local
        // localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
        localData.keyView = 'etablissement_environnants';
        saveData(JSON.parse(JSON.stringify(localData))); // on enregistre

        // on notifie 
      notification.open({
        message:(
           <div style={{ color: 'white' }}>
             Enregistrer avec success
           </div>
          ),
        description: '',
        placement:'bottomLeft', 
        icon: <CheckCircleOutlined />,
        style: {
            backgroundColor: 'green', // Couleur de fond personnalisée
            color:'white',
            // Autres styles personnalisés...
          },
      })

      setMainView('etablissement_environnants');


        } catch (error) {
          console.error('Erreur lors de l\'enregistrement des données', error);
          setLoading(false);
        }
      };


    return (
        <>
<div class="container-fluid">

<button style={{width:'20%'}} onClick={() => { 

setRelationId(datas.rentreer_esb); //on passe l'ID du rapport
setMainView('synthese_effectifs'); // on passe la cle de la vue a retourner

}}  className="btn btn-success" >
<DoubleLeftOutlined />
&nbsp;&nbsp;
 retour
 
</button>

<br /><br />

<div class="card mb-3">
    <div class="card-body">
        <h2> Repartition des effectifs des trois dernieres annees </h2>
    </div>
</div>

 <div class="card mb-3">
    <div class="card-body">

    <table className="table" border={1}>
        <thead>
          <tr>
            <th>Classes</th>
            {years.map(year => (
              <th key={year}>{year - 1} - {year}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item.classe}>
              <td>{item.classe}</td>
              {years.map(year => (
                <td key={year}>
                  <input
                    type="number"
                    value={item[year].G}
                    onChange={(e) => handleChange(year, item.classe, 'G', e.target.value)}
                    placeholder="G"
                  />
                  <input
                    type="number"
                    value={item[year].B}
                    onChange={(e) => handleChange(year, item.classe, 'B', e.target.value)}
                    placeholder="B"
                  />
                  <span>{item[year].total}</span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={handleSubmit} disabled={loading}>
        {loading?<>
            Enregistrement en cours
        </>:<>
            Suivant
        </>}
      </button>
    
    </div>
  </div>
</div>
<br /><br /><br />
        </>
    );
}