// LoginPage.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { useCookies } from "react-cookie";

import './css/LoginPage.css'; // Assurez-vous d'avoir un fichier CSS séparé
import { langState } from '../atoms/AppAtome';
import axios from 'axios';
import { APP_URL } from '../URL';
import { Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import img from '../assets/P_OneBlackTeacher03-e1491999410888 (1).jpg';

const LoginPage = () => {

  const { t } = useTranslation();
  const [lang, setLang] = useRecoilState(langState);

  const [formData, setFormData] = useState({
    username:'',
    password:'',
    typeRaport: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name] : e.target.value
    });
  };

 

  
  const [loading, setLoading] = useState(false);
  const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
  const navigate = useNavigate();


  const handleSubmit = (e) => {

    e.preventDefault();

    if(formData.username != '' && formData.password != ''){
    setLoading(true);
    // Logique de connexion ici
    axios.post(APP_URL+'login_check',formData).then((response) => {

      console.log(response.data);
      setLoading(false);

      // on enregistre le token dans le cookies
      const expiresT = new Date();
         expiresT.setHours(expiresT.getHours() + 1);

         setCookie('seduc_token',response.data.token,{path:'/', expires: expiresT}); // on enregistre le Token
         return navigate('/lookup');

    }).catch((error) => {

      message.error(error.response.data.message);
      console.log(error);
      setLoading(false);

    });

   }else{
    message.warning('un ou plusieurs champs sont manquantes');
   }

  };

  const handleLanguageChange = (selectedLang) => {
    setLang(selectedLang);
  };

  return (
    <div className="login-container" id='zone'>
      <div className="language-selector">
        <a href='/'>Retour en Accueil</a>
        <br />
        {/* <label htmlFor="langSelect">{t('selectLanguage')}</label> */}
        <select id="langSelect" className='form-control' onChange={(e) => handleLanguageChange(e.target.value)} value={lang}>
          <option value="en">English</option>
          <option value="fr">Français</option>
        </select>
      </div>

      <div className="login-form" id='log'>
        <center>
            <h3>{t('login')}</h3>
        </center>
        
        <form onSubmit={handleSubmit}>
          <label htmlFor="username">{t('username')}</label>
          <input type="text" name='username' id="username" className='form-control' value={formData.username} onChange={handleChange} />
            <br />
          <label htmlFor="password">{t('password')}</label>
          <input type="password" name='password' className='form-control' id="password" value={formData.password} onChange={handleChange} />
            
             <a href=''>Mot de pass oublier ?</a>
             <br /><br />
             
          <button type="submit" disabled={loading} className='btn btn-primary'>{t('loginButton')}</button>
        </form>
        <br /><br />
      </div>
      <br /><br />
      <center>
        <span style={{color:'white'}}>&copy; powered by <a style={{color:'white'}} href='https://www.consolidis.com' target='_blank'><u>Consolidis</u></a></span>
      </center>

      <style jsx>{`
        #zone{
          background:url("${img}");
          background-size:cover;
        }
        #log{
           background-color: rgba(238, 237, 237, 0.8);
        }
      `}</style>
    </div>
  );
};

export default LoginPage;
