import { useRecoilState } from "recoil";
import { detailProcessState, isOkProcessState, keyViewProcessState, mainViewState, relationIdState, rentreeEsbState } from "../../../../atoms/AppAtome";
import { useEffect, useState } from "react";
import { Button, notification } from "antd";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { useCookies } from "react-cookie";

export default function Enseignement(){
    const [etape, setEtape] = useRecoilState(rentreeEsbState);
    const [isOk, setIsOk] = useRecoilState(isOkProcessState);
    const [detailProcess, setDetailProcess] = useRecoilState(detailProcessState);
    const [keyView, setKeyView] = useRecoilState(keyViewProcessState);
    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);


    const [sousSysAnglo, setSousSysAnglo] = useState(false);
    const [sousSysFrango, setSousSysFrango] = useState(false);
    const [classConcerne, setClassConcerne] = useState('');
    const [autreTypeBilingue, setAutreTypeBillingue] = useState('');
    const [typeCycleAutorises, setTypeCycleAutorises] = useState('');
    const [typeCycleNonAutorises, setTypeCycleNonAutorises] = useState('');
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };
  

    const { saveData, readData } = useLocalStorage(); // le hook pour enregistrer les donnees dans la memoire local

    useEffect(() => {
        setKeyView('etape1_esb_enseignement');
        window.scrollTo({ top: 0, behavior: "smooth" });
        console.log(readData()); // on affiches les donnees recuperer dans la memoire local
    },[]);

    const handleNext = () => {

        // if((sousSysAnglo == false && sousSysFrango == false) || classConcerne == '' && autreTypeBilingue == '' || typeCycleAutorises == ''){

        //     notification.open({
        //         message:(
        //            <div style={{ color: 'white' }}>
        //              un ou plueieurs champs sont manquants dans le formulaire.
        //            </div>
        //           ),
        //         description: '',
        //         placement:'bottomLeft', 
        //         icon: <WarningOutlined />,
        //         style: {
        //             backgroundColor: 'red', // Couleur de fond personnalisée
        //             color:'white',
        //             // Autres styles personnalisés...
        //           },
        //       })
  
        // }else{
            setLoading(true); 
            let localData  = readData(); // on recupere les donnees de la memoire local
            axios.post(APP_URL+'esb/save-enregistrement',{
                'sousSysAnglo' : sousSysAnglo,
                'sousSysFrango' : sousSysFrango,
                'classConcerne' : classConcerne,
                'autreTypeBilingue' : autreTypeBilingue,
                'typeCycleAutorises' : typeCycleAutorises,
                'typeCycleNonAutorises' : typeCycleNonAutorises,
                'rentree_esb_id' : localData.rentreer_esb,
            },config).then((response) =>{
                console.log(response.data);
                setMainView('trans1'); // on envoie la vue pour enregistrer la premiere trans
                setRelationId(response.data.enseignement_id); // on passe l'id de l'enseignement en cours d'enregistrement
        
               
                localData.keyView = 'trans1'; // on enregistre la vue en cours
        
                localData.relationId = response.data.enseignement_id; // on enregistre l'id de l'enseignement
               
                 // on notifie 
              notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

                saveData(JSON.parse(JSON.stringify(localData)));

              }).catch((error) => {
                console.log(error);
            })
        // }

       

    }


    return (
        <>
    <div class="container-fluid">
     
     {/* <ol class="breadcrumb">
       <li class="breadcrumb-item">
         <a href="#"> <h3>Etape {etape}</h3> </a>
       </li>
       <li class="breadcrumb-item active">Enseignement</li>
     </ol> */}

     <div class="card mb-3">
        <div class="card-header">
          <i class="fa fa-table"></i> 
        </div>
        <div class="card-body">
          <div class="table-responsive">
                 
          <div className="form-container">
            <h6>1-Sous système</h6>
            <div className="checkbox-group">
                <input type="checkbox" checked={sousSysAnglo} onChange={() => setSousSysAnglo(!sousSysAnglo)} id="v" />
                <label htmlFor="v">ANGLOPHONE</label>
                <input type="checkbox" checked={sousSysFrango} onChange={() => setSousSysFrango(!sousSysFrango)} id="v1" />
                <label htmlFor="v1">FRANCOPHONE</label>
            </div>

            <h6>Bilingue 50/50: Quelles sont les classes concernées?</h6>
            <input type="text" value={classConcerne} onChange={(e) => setClassConcerne(e.target.value)} className="form-control" />

            <h6>Autre type de bilinguisme</h6>
            <input type="text" value={autreTypeBilingue} onChange={(e) => setAutreTypeBillingue(e.target.value)} className="form-control" />

            <h6>Type et Cycles autorisés</h6>
            <input type="text" value={typeCycleAutorises} onChange={(e) => setTypeCycleAutorises(e.target.value)} className="form-control" />

            <h6>Cycles non autorisés</h6>
            <input type="text" value={typeCycleNonAutorises} onChange={(e) => setTypeCycleNonAutorises(e.target.value)} className="form-control" />

            <br />
            <Button className="btn btn-primary" loading={loading} onClick={handleNext}>Suivant <span className=""> </span></Button>
        </div>
        </div>
        </div>
        <div class="card-footer small text-muted"></div>
      </div>
	 <br /><br /><br /><br /><br />  
      </div> 

      <style jsx>{`
        .form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.checkbox-group {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.checkbox-group input[type="checkbox"] {
    margin-right: 5px;
}

.form-control {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    box-sizing: border-box;
}

h6 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

      `}</style>
        </>
    );
}