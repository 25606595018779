import { useRecoilState } from "recoil";
import { aspectsFinanciersIdState, mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Button, notification } from "antd";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { CheckCircleOutlined } from "@ant-design/icons";


export default function EtatNominatif(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
    const [aspectFinancierId, setAspectFinancierid] = useRecoilState(aspectsFinanciersIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();

    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };

        const [data, setData] = useState(
            Array.from({ length: 20 }, () => ({
              nom: '',
              prenom: '',
              diplome: '',
              categorie: '',
              classe: '',
              numAutorisation: '',
              salairesBrutAnnuels: '',
              indemnitesAnnuelles: '',
              cumulSalairesPercus: '',
              retenuesAnnuelles: '',
              montantSalairesDus: '',
              emargement: ''
            }))
          );

          const handleChange = (index, field, value) => {
            const newData = [...data];
            newData[index][field] = value;
            setData(newData);
          };

          const handleSubmit = async () => {

            setLoading(true);

            try {
              await axios.post(APP_URL+'save-etat-nominatif/'+datas.rentreer_esb, { data },config).then((response) => {
                setLoading(false);
                let localData  = readData(); // on recupere les donnees de la memoire local
                localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
                localData.keyView = 'repartitionEleveAgeSexeClasse';
                // localData.isOk = true;
                saveData(JSON.parse(JSON.stringify(localData))); // on enregistre
    
                 // on notifie 
              notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })
    
              setMainView('repartitionEleveAgeSexeClasse');

              });
              
            } catch (error) {
              console.error('Erreur lors de l\'enregistrement des données', error);
              setLoading(false);
            }
          };

        /*const handleNext = () => {
            setLoading(true);

            axios.post(APP_URL+'esb/save-nominatif/'+datas.rentreer_esb,{
                'nomPrenom':nomPrenom,
                'diplomeEleve':diplomeEleve,
                'categorie':categorie,
                'classEnseigne':classEnseigne,
                'numAutEnseigner':numAutEnseigner,
                'salaireButeAnnuel':salaireButeAnnuel,
                'indemniteAnnuel':indemniteAnnuel,
                'cumulSalairePercusDate':cumulSalairePercusDate,
                'retenuesAnnuel':retenuesAnnuel,
                'montantSalaire':montantSalaire,
                'emargement':emargement
            },config).then((response) => {

                let localData  = readData(); // on recupere les donnees de la memoire local
                localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
                localData.keyView = 'endd';
                localData.isOk = true;
                saveData(JSON.parse(JSON.stringify(localData))); // on enregistre
    
                setAspectFinancierid(response.data.datas.aspectFinancierId);
                
                 // on notifie 
              notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })
    
              setMainView('endd');
    
            }).catch((error) => {
                console.log(error);
                setLoading(false);
            })
        } */


    return (
        <>
<div class="container-fluid">

<div class="card mb-3">
    <div class="card-body">
        <h2> Etat nominatif du personnels </h2>
    </div>
</div>

 <div class="card mb-3">
    <div class="card-body">

    <div className="alert alert-warning">
        Tout en bas, faite scroller de la gauche vers la droite pour voir la suite du tableau
    </div>
   <div style={{overflow:'scroll', width:'1050px'}}>
    <table className="table" border="1">
        <thead>
          <tr>
            <th>Ordre</th>
            <th>NOMS ET PRENOMS</th>
            <th>DIPLOME LE PLUS ELEVE</th>
            <th>CATEGORIE</th>
            <th>CLASSE ENSEIGNEE</th>
            <th>N° AUT. D'ENSEIGNER</th>
            <th>SALAIRES BRUT ANNUELS</th>
            <th>INDEMNITE ANNUELLE</th>
            <th>CUMUL SALAIRES PERCUS EN DATE</th>
            <th>RETENUES ANNUELLES</th>
            <th>MONTANT SALAIRES DUS</th>
            <th>EMARGEMENT</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td><input type="text" value={row.nom} onChange={(e) => handleChange(index, 'nom', e.target.value)} /></td>
              <td><input type="text" value={row.prenom} onChange={(e) => handleChange(index, 'prenom', e.target.value)} /></td>
              <td><input type="text" value={row.diplome} onChange={(e) => handleChange(index, 'diplome', e.target.value)} /></td>
              <td><input type="text" value={row.categorie} onChange={(e) => handleChange(index, 'categorie', e.target.value)} /></td>
              <td><input type="text" value={row.classe} onChange={(e) => handleChange(index, 'classe', e.target.value)} /></td>
              <td><input type="text" value={row.numAutorisation} onChange={(e) => handleChange(index, 'numAutorisation', e.target.value)} /></td>
              <td><input type="text" value={row.salairesBrutAnnuels} onChange={(e) => handleChange(index, 'salairesBrutAnnuels', e.target.value)} /></td>
              <td><input type="text" value={row.indemnitesAnnuelles} onChange={(e) => handleChange(index, 'indemnitesAnnuelles', e.target.value)} /></td>
              <td><input type="text" value={row.cumulSalairesPercus} onChange={(e) => handleChange(index, 'cumulSalairesPercus', e.target.value)} /></td>
              <td><input type="text" value={row.retenuesAnnuelles} onChange={(e) => handleChange(index, 'retenuesAnnuelles', e.target.value)} /></td>
              <td><input type="text" value={row.montantSalairesDus} onChange={(e) => handleChange(index, 'montantSalairesDus', e.target.value)} /></td>
              <td><input type="text" value={row.emargement} onChange={(e) => handleChange(index, 'emargement', e.target.value)} /></td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <br /><br />
      <button disabled={loading} onClick={handleSubmit}>
        {loading?<>
            Enregistrement en cours
        </>:<>
            Suivant
        </>}
      </button>
   
    </div>
 </div>
</div>
<br /><br /><br />
        </>
    );
}