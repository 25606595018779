import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useRecoilState } from "recoil";
import { aspectsFinanciersIdState, mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { notification } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { APP_URL } from "../../../../URL";


export default function RepartitionAgeSexeClasse(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
    const [aspectFinancierId, setAspectFinancierid] = useRecoilState(aspectsFinanciersIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();

    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };


    const [data, setData] = useState(Array.from({ length: 13 }, () => ({
        Maternelle: { G: 0, F: 0 },
        SIL: { G: 0, F: 0 },
        CP: { G: 0, F: 0 },
        CE1: { G: 0, F: 0 },
        CE2: { G: 0, F: 0 },
        CM1: { G: 0, F: 0 },
        CM2: { G: 0, F: 0 },
        TOTAL: { G: 0, F: 0 }
      })));
    
      const [totals, setTotals] = useState({
        Maternelle: { G: 0, F: 0 },
        SIL: { G: 0, F: 0 },
        CP: { G: 0, F: 0 },
        CE1: { G: 0, F: 0 },
        CE2: { G: 0, F: 0 },
        CM1: { G: 0, F: 0 },
        CM2: { G: 0, F: 0 },
        TOTAL: { G: 0, F: 0 }
      });

      const [loading, setLoading] = useState(false);

      useEffect(() => {
        const newTotals = {
          Maternelle: { G: 0, F: 0 },
          SIL: { G: 0, F: 0 },
          CP: { G: 0, F: 0 },
          CE1: { G: 0, F: 0 },
          CE2: { G: 0, F: 0 },
          CM1: { G: 0, F: 0 },
          CM2: { G: 0, F: 0 },
          TOTAL: { G: 0, F: 0 }
        };

        data.forEach(row => {
            for (const [key, value] of Object.entries(row)) {
              newTotals[key].G += value.G;
              newTotals[key].F += value.F;
              newTotals.TOTAL.G += value.G;
              newTotals.TOTAL.F += value.F;
            }
          });
      
          setTotals(newTotals);
        }, [data]);

        const handleChange = (ageIndex, classType, gender, value) => {
            const newData = [...data];
            newData[ageIndex][classType][gender] = parseInt(value) || 0;
            setData(newData);
          };

          const handleSubmit = async () => {
            setLoading(true);

            try {

              await axios.post(APP_URL+'save-repartition-age-sexe-classe/'+datas.rentreer_esb, { data, totals },config);
              
              setLoading(false);

              let localData  = readData(); // on recupere les donnees de la memoire local
                // localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
                localData.keyView = 'endd';
                localData.isOk = true;
                saveData(JSON.parse(JSON.stringify(localData))); // on enregistre
    
                 // on notifie 
              notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })
    
              setMainView('endd');
    
            } catch (error) {
              console.error('Erreur lors de l\'enregistrement des données', error);
              setLoading(false);
            }
          };
      
    return (
        <>
    <div class="container-fluid">
     
     <table border="1" className="">
        <thead>
          <tr>
            <th>Moins de</th>
            <th colSpan="2">Maternelle</th>
            <th colSpan="2">SIL</th>
            <th colSpan="2">CP</th>
            <th colSpan="2">CE1</th>
            <th colSpan="2">CE2</th>
            <th colSpan="2">CM1</th>
            <th colSpan="2">CM2</th>
            <th colSpan="2">TOTAL</th>
            <th>Observations</th>
          </tr>
          <tr>
            <th></th>
            {Array.from({ length: 8 }, (_, i) => (
              <>
                <th key={`G-${i}`}>G</th>
                <th key={`F-${i}`}>F</th>
              </>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, ageIndex) => (
            <tr key={ageIndex}>
              <td>{`${4 + ageIndex} ans`}</td>
              {Object.entries(row).map(([classType, genders]) => (
                <>
                  <td key={`${classType}-G`}>
                    <input
                      type="number"
                      value={genders.G}
                      onChange={(e) => handleChange(ageIndex, classType, 'G', e.target.value)}
                    />
                  </td>
                  <td key={`${classType}-F`}>
                    <input
                      type="number"
                      value={genders.F}
                      onChange={(e) => handleChange(ageIndex, classType, 'F', e.target.value)}
                    />
                  </td>
                </>
              ))}
              <td></td>
            </tr>
          ))}
          <tr>
            <td>Total</td>
            {Object.entries(totals).map(([classType, genders]) => (
              <>
                <td key={`${classType}-G-total`}>{genders.G}</td>
                <td key={`${classType}-F-total`}>{genders.F}</td>
              </>
            ))}
            <td></td>
          </tr>
        </tbody>
      </table>
      <br /><br />
      <button disabled={loading} onClick={handleSubmit}>
        {loading?<>
            Enregistremet en cours
        </>:<>
            Terminer maintenant
        </>}
      </button>
    
    </div>
    <br /><br /><br />
        </>
    );
}