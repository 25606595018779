import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useCookies } from "react-cookie";
import { Button, notification } from "antd";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { CheckCircleOutlined, DoubleLeftOutlined } from "@ant-design/icons";


export default function EtablissementEnvironnant(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();

    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const [nomEtablissement, setNomEtablissement] = useState('');
    const [quartier, setQuartier] = useState('');
    const [nomFondateur, setNomFondateur] = useState('');
    const [contact, setContact] = useState('');
    

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };

        const [schools, setSchools] = useState(
            Array.from({ length: 10 }, () => ({
              name: '',
              quarter: '',
              founder: '',
              contact: ''
            }))
          );

          useEffect(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          },[]);
        
          const handleChange = (index, field, value) => {
            const updatedSchools = [...schools];
            updatedSchools[index][field] = value;
            setSchools(updatedSchools);
          };

          const handleSubmit = async () => {
            setLoading(true);

            try {
              await axios.post(APP_URL+'save-etablissement-environnant/'+datas.rentreer_esb, schools,config);
              setLoading(false);

              let localData  = readData(); // on recupere les donnees de la memoire local
            //   localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
              localData.keyView = 'aspects_finanncier';
              saveData(JSON.parse(JSON.stringify(localData))); // on enregistre

                // on notifie 
          notification.open({
            message:(
               <div style={{ color: 'white' }}>
                 Enregistrer avec success
               </div>
              ),
            description: '',
            placement:'bottomLeft', 
            icon: <CheckCircleOutlined />,
            style: {
                backgroundColor: 'green', // Couleur de fond personnalisée
                color:'white',
                // Autres styles personnalisés...
              },
          })

          setMainView('aspects_finanncier');


              

            } catch (error) {
              console.error('Erreur lors de l\'enregistrement des données', error);
              setLoading(false);
            }
          };

    /*const handleNext = () => {
        setLoading(true);

        axios.post(APP_URL+'esb/save-etablissement-environnement/'+datas.rentreer_esb,{
            'nomEtablissement':nomEtablissement,
            'quartier':quartier,
            'nomFondateur':nomFondateur,
            'contact':contact,
        },config).then((response) => {

            setLoading(false);

            let localData  = readData(); // on recupere les donnees de la memoire local
            localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
            localData.keyView = 'aspects_finanncier';
            saveData(JSON.parse(JSON.stringify(localData))); // on enregistre
            console.log('REP',response.data);

            // on notifie 
          notification.open({
            message:(
               <div style={{ color: 'white' }}>
                 Enregistrer avec success
               </div>
              ),
            description: '',
            placement:'bottomLeft', 
            icon: <CheckCircleOutlined />,
            style: {
                backgroundColor: 'green', // Couleur de fond personnalisée
                color:'white',
                // Autres styles personnalisés...
              },
          })

          setMainView('aspects_finanncier');


        }).catch((error) => {
            setLoading(false);
            console.log(error);
        })
    } */

    return (
        <>
<div class="container-fluid">
<button style={{width:'20%'}} onClick={() => { 

setRelationId(datas.rentreer_esb); //on passe l'ID du rapport
setMainView('repartition_effectifs_trois_annees'); // on passe la cle de la vue a retourner

}}  className="btn btn-success" >
<DoubleLeftOutlined />
&nbsp;&nbsp;
 retour
 
</button>

<br /><br />
<div class="card mb-3">
    <div class="card-body">
        <h2> Etablissements Environnants </h2>
    </div>
</div>

 <div class="card mb-3">
    <div class="card-body">

    <table className="table" border={1}>
        <thead>
          <tr>
            <th>Ord</th>
            <th>Nom de l'établissement</th>
            <th>Quartier</th>
            <th>Nom du Fondateur</th>
            <th>Contact</th>
          </tr>
        </thead>
        <tbody>
          {schools.map((school, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <input
                  type="text"
                  value={school.name}
                  onChange={(e) => handleChange(index, 'name', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={school.quarter}
                  onChange={(e) => handleChange(index, 'quarter', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={school.founder}
                  onChange={(e) => handleChange(index, 'founder', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={school.contact}
                  onChange={(e) => handleChange(index, 'contact', e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={handleSubmit} disabled={loading}>
        {loading?<>
            Enregistrement en cours
        </>:<>
            Suivant 
        </>}
      </button>
    
    </div>
 </div>
</div>
<br /><br /><br />
        </>
    );
}